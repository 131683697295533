require("dotenv").config();

const API_KEY = process.env.GOOGLE_MAPS_API_KEY;

if (!API_KEY) {
  console.error("Google Maps API Key is missing!");
}

// Load the Google Maps script dynamically
const script = document.createElement("script");
script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`;
script.async = true;
script.defer = true;
document.head.appendChild(script);

let map;
let userLocation;
let markers = [];
let totalBars = 0;
let openBars = 0;
const placeTypes = ["bar"]; // You can add other types if needed (e.g., "pub")

// Initialize Google Map
function initMap() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        map = new google.maps.Map(document.getElementById("map"), {
          center: userLocation,
          zoom: 14,
        });

        // Add a marker for the user's current location
        new google.maps.Marker({
          position: userLocation,
          map,
          title: "You are here",
        });

        // Fetch open bars after initializing the map
        fetchOpenBars();
      },
      (error) => {
        console.error("Error getting geolocation:", error);
        alert(
          "Unable to retrieve your location. Please enable location services and try again."
        );
      }
    );
  } else {
    alert("Geolocation is not supported by your browser.");
  }
}

// Zoom to user's location
function zoomToLocation() {
  if (!map) {
    alert("Map is not initialized. Please wait and try again.");
    return;
  }

  if (userLocation) {
    map.setCenter(userLocation);
    map.setZoom(14);
  } else {
    alert("Unable to zoom: User location is not set.");
  }
}

// Fetch open bars and update the map and gauge
function fetchOpenBars() {
  if (!userLocation) {
    console.error("User location is not available yet.");
    return;
  }

  const service = new google.maps.places.PlacesService(map);
  let openBarsFetched = 0;
  let totalBarsFetched = 0;

  // Clear existing markers from the map
  clearMarkers();

  // Fetch open and total bars for each place type
  placeTypes.forEach((type) => {
    // Fetch open bars
    service.nearbySearch(
      { location: userLocation, radius: 5000, type: type, openNow: true },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          openBars += results.length;

          // Add markers for open bars
          results.forEach((place) => {
            const marker = new google.maps.Marker({
              position: place.geometry.location,
              map,
              title: `${place.name} (Open)`,
            });
            markers.push(marker);
          });
        }
        openBarsFetched++;
        checkAndUpdateGauge(openBarsFetched, totalBarsFetched);
      }
    );

    // Fetch total bars
    service.nearbySearch(
      { location: userLocation, radius: 5000, type: type },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          totalBars += results.length;
        }
        totalBarsFetched++;
        checkAndUpdateGauge(openBarsFetched, totalBarsFetched);
      }
    );
  });
}

// Clear all markers from the map
function clearMarkers() {
  markers.forEach((marker) => marker.setMap(null));
  markers = [];
}

// Update the gauge and text once all data is fetched
function checkAndUpdateGauge(openBarsFetched, totalBarsFetched) {
  if (
    openBarsFetched === placeTypes.length &&
    totalBarsFetched === placeTypes.length
  ) {
    updateGauge();
  }
}

// Update the gauge and display the data
function updateGauge() {
  const percentage =
    totalBars > 0 ? Math.round((openBars / totalBars) * 100) : 0;
  gauge.refresh(percentage);

  // Update the text below the gauge
  const openBarsText = `${openBars} of ${totalBars} bars open`;
  document.getElementById("open-bars-text").innerText = openBarsText;
}

// JustGage Initialization
const gauge = new JustGage({
  id: "gauge-container",
  value: 0,
  min: 0,
  max: 100,
  title: "Open Bars",
  label: "%",
  levelColors: ["#32CD32", "#FFFF00", "#FFA500", "#FF4500"], // Green to Red
});

// Toggle Night Mode
function toggleTheme() {
  const body = document.body;
  const currentTheme = body.getAttribute("data-theme");
  const newTheme = currentTheme === "dark" ? "" : "dark";
  body.setAttribute("data-theme", newTheme);
}

// Event Listeners
document.addEventListener("DOMContentLoaded", () => {
  initMap();
  fetchOpenBars();
});
document
  .getElementById("zoom-location-btn")
  .addEventListener("click", zoomToLocation);
document.querySelector(".toggle-btn").addEventListener("click", toggleTheme);

// Refresh data periodically (every 30 seconds)
setInterval(() => {
  openBars = 0;
  totalBars = 0;
  fetchOpenBars();
}, 30000);
